// 0.待填写 1.待审批 2.待签署 3.待签章 10.已填写 11.已审批 12.已签署 13.已签章
export const FILE_STATUS_TEXT = {
  0: '待填写',
  1: '待审批',
  2: '待签署',
  3: '待签章',
  10: '已填写',
  11: '已审批',
  12: '已签署',
  13: '已签章',
}

export const FILE_STATUS = {
  /**
   * 待填写 - 0
   */
  WAIT_WRITE_VAR: 0,
  /**
   * 待审批 - 1
   */
  WAIT_APPROVAL: 1,
  /**
   * 待签署
   */
  WAIT_SIGN_NAME: 2,
  /**
   * 待签章
   */
  WAIT_SIGN_SEAL: 3,
  /**
   * 已填写
   */
  HAS_WROTE_VAR: 10,
  /**
   * 已审批
   */
  HAS_APPROVED: 11,
  /**
   * 已签署
   */
  HAS_SIGNED_NAME: 12,
  /**
   * 已签章
   */
  HAS_SIGNED_SEAL: 13,
}
