<template>
  <!-- 暂时弃用 -->
  <SignFileWrap>
    <div class="catFile">
      <!-- <div class="file-img-list" v-html="htmlFilter"></div> -->
      <iframe
        :srcdoc="currentFile.fileHtml"
        frameborder="0"
        :style="{
          width: `100%`,
          height: `100vh`,
        }"
        class="doc-iframe"
        id="MyIFrame"
      ></iframe>
      <!-- <div class="htmlFilter" v-html="this.currentFile.fileHtml"></div> -->
      <!-- <ul class="file-img-list" ref="catFile">
        <li
          class="file-img-item"
          v-for="(item, index) in currentFile.fileKeyList"
          :key="index"
        >
          <img :src="item" alt="" @click.self="showImg(index)" />
        </li>
      </ul> -->
      <div class="footer">
        <div class="btn" @click="onClick">填写文件内容</div>
      </div>
    </div>
  </SignFileWrap>
</template>
<script>
  // 当前页面暂时弃用
  import { mapState } from 'vuex'
  import { ImagePreview } from 'vant'
  import SignFileWrap from '@/components/SignFileWrap.vue'
  // import Hammer from 'hammerjs';

  export default {
    components: { SignFileWrap },
    data() {
      return {}
    },
    computed: {
      ...mapState({
        currentFile: state => state.contractSign.currentFile,
      }),
      // htmlFilter() {
      //   if (!this.currentFile.fileHtml) return;
      //   /* eslint-disable-next-line */
      //   return this.currentFile.fileHtml.replace(/<link.*?>/g, '');
      // },
    },
    created() {
      // console.log(this.currentFile)
    },
    updated() {
      this.$nextTick(() => {
        const myIframe = document.getElementById('MyIFrame')
        const iframeDoc = myIframe.contentDocument
        const styleFragment = document.createElement('style')
        styleFragment.innerText = `#tinymce{width:100vw !important;margin:0;overflow-x:hidden;} body{width:100%;} `
        iframeDoc.head.append(styleFragment)
      })
    },
    mounted() {
      // this.$nextTick(() => {
      const myIframe = document.getElementById('MyIFrame')

      myIframe.contentDocument.write(this.currentFile.fileHtml)
      const iframeDoc = myIframe.contentDocument
      // 关闭 body 的可编辑功能
      iframeDoc.body.contentEditable = false
      this.$nextTick(() => {
        const styleFragment = document.createElement('style')
        styleFragment.innerText = `#tinymce{width:100% !important;margin:0;overflow-x:hidden;} body{width:100%;} `
        iframeDoc.head.append(styleFragment)
      })

      // document.getElementById('MyIFrame').onload = () => {
      //   const iframe = document.getElementById('MyIFrame')

      //   const iframeDoc = iframe.contentDocument
      //   const styleFragment = document.createElement('style')
      //   console.log(iframeDoc)
      //   styleFragment.type = 'text/css'
      //   styleFragment.innerText = `#tinymce{width:100% !important;} `
      //   iframeDoc.head.append(styleFragment)
      //   if (Array.isArray(varList)) {
      //     varList.forEach(item => {
      //       const varDom = iframeDoc.querySelector(`#${item.varName}`)
      //       if (!varDom) return
      //       varDom.style.backgroundColor = '#0090ff'
      //     })
      //   }
      // }
      // })
    },
    methods: {
      showImg(index) {
        ImagePreview({
          images: this.currentFile.fileKeyList,
          startPosition: index,
          closeOnPopstate: true,
        })
      },
      onClick() {
        console.log('currentFile', this.currentFile)
        window.sessionStorage.isBackCatFile = true
        // this.$router.go(-1)
        const { businessId, isLauncher } = this.$route.query
        const query = {
          subId: this.currentFile.subId,
          isLauncher,
          businessId,
          back: '1',
        }
        this.$router.push({
          path: '/signFile',
          query,
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .catFile {
    width: 100%;
    // padding: 16px;
    font-size: 16px;
    // padding-bottom: 70px;
    background: #fff;
    padding-bottom: 70px;
    box-sizing: border-box;
    iframe {
      min-height: calc(~'100vh - 100px');
    }
    .file-img-list {
      width: 100%;
      .file-img-item {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .footer {
      position: fixed;
      -webkit-transform: translateZ(0);
      bottom: 0;
      left: 0;
      width: 375px;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        width: 335px;
        height: 50px;
        background: #1676ff;
        border-radius: 25px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>

<style lang="less">
  .doc-iframe {
    width: 100vw;
    height: 100%;
    document {
      width: 100vw;
      /deep/ .tinymce {
        width: 100vw !important;
      }
    }
  }
</style>
