<template>
  <div class="cat-file">
    <div class="header">
      <div class="inner">
        <!-- <img :src="currentFile.fileFormat" alt="" class="header-img" /> -->
        <SvgIcon :type="getFileType" class="header-img" />
        <div class="text">
          <div class="title">{{ currentFile.fileName }}</div>
          <div class="extra">
            <span
              class="file-status"
              :class="`file-status__${currentFileStatusClass}`"
              >{{ currentFileStatusText }}</span
            >
            <span class="size">128kb</span>
          </div>
        </div>
        <div v-if="fileList.length > 1" class="icon-content" @click="onSelect">
          更换
          <span class="icon" :class="isShowSelect ? 'icon2' : ''"></span>
        </div>
      </div>
      <ul
        class="select"
        :style="{
          opacity: isShowSelect ? 1 : 0,
          maxHeight: isShowSelect ? '300px' : 0,
        }"
        @touchstart="bodyHidden"
        @touchend="bodyVisible"
      >
        <li
          class="select-item"
          v-for="(item, index) in fileList"
          :key="index"
          :class="subId === item.subId ? 'active-item' : ''"
          @click="onChange(item)"
        >
          {{ item.fileName }}
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="inner" @click="onClick">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { FILE_STATUS, FILE_STATUS_TEXT } from '@/common/fileStatusEnum'

  export default {
    data() {
      return {
        isShowSelect: false,
      }
    },
    computed: {
      ...mapState({
        fileList: state => state.contractSign.fileList,
        subId: state => state.contractSign.subId,
        currentFile: state => state.contractSign.currentFile,
      }),
      currentFileStatus() {
        return this.currentFile.status
      },
      currentFileStatusText() {
        return FILE_STATUS_TEXT[this.currentFileStatus]
      },
      currentFileStatusClass() {
        const successStatus = [
          FILE_STATUS.HAS_WROTE_VAR,
          FILE_STATUS.HAS_APPROVED,
          FILE_STATUS.HAS_SIGNED_NAME,
          FILE_STATUS.HAS_SIGNED_SEAL,
        ]
        if (successStatus.includes(this.currentFileStatus)) {
          return `succeed`
        }
        return `wait`
      },
      getFileType() {
        let str = ''
        if (
          this.currentFile.fileFormat === 'jpg' ||
          this.currentFile.fileFormat === 'png' ||
          this.currentFile.fileFormat === 'svg'
        ) {
          str = 'jpeg'
        } else if (
          this.currentFile.fileFormat === 'doc' ||
          this.currentFile.fileFormat === 'docx'
        ) {
          str = 'word'
        } else if (this.currentFile.fileFormat === 'pdf') {
          str = 'pdf'
        } else if (this.currentFile.fileFormat === 'ppt') {
          str = 'ppt'
        } else {
          str = 'default'
        }
        return str
      },
    },
    mounted() {
      console.log(this.currentFile)
    },

    methods: {
      bodyHidden() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      },
      bodyVisible() {
        document.getElementsByTagName('body')[0].style.overflow = 'visible'
      },
      onClick() {
        this.isShowSelect = false
      },
      onSelect() {
        this.isShowSelect = !this.isShowSelect
      },
      onChange(fileData) {
        this.onSelect()
        this.$store.dispatch('contractSign/changeFile', {
          fileData,
          isChange: true,
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .cat-file {
    // height: 100vh;
    .header {
      position: sticky;
      z-index: 1000;
      left: 0;
      top: 0;
      width: 100%;
      height: 68px;
      background: #f4f5f6;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0;
      box-sizing: border-box;
      .select {
        position: absolute;
        transition: all 0.4s;
        top: 58px;
        left: 16px;
        width: 343px;
        z-index: 999;
        // height: 130px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 6px 10px 0px rgba(230, 234, 237, 0.3);
        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: auto !important;
        .select-item {
          width: 343px;
          padding: 10px;
          // height: 40px;
          background: #fff;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          // text-align: center;
          color: #5f6368;
          // line-height: 40px;
          white-space: nowrap;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .active-item {
          background-color: #ebf3ff;
        }
      }
      /* 默认显示滚动条 */
      .select::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
      }
      .select::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #d8d8d8;
      }
      .inner {
        width: 343px;
        //height: 36px;
        // background: #f4f5f6;
        // border-radius: 16px;
        // padding: 0 16px;
        display: flex;
        align-items: center;
        .header-img {
          font-size: 36px;
          margin-right: 7px;
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          color: #111a34;
          flex: 1;
          margin-right: 10px;
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .title {
            font-size: 14px;
            color: #1f2023;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .extra {
            margin-top: 4px;
            display: flex;
            align-items: center;
            .file-status {
              padding: 2px 8px;
              border-radius: 2px;
              font-size: 10px;
              font-weight: 500;
              // 成功态
              &.file-status__succeed {
                background: #ebf3ff;
                color: @BLUE;
              }
              // 等待态
              &.file-status__wait {
                background: #feeeec;
                color: #ff6e48;
              }
            }
            .size {
              margin-left: 6px;
              font-size: 11px;
              color: #747980;
            }
          }
        }
        .icon-content {
          font-size: 12px;
          display: flex;
          align-items: center;
        }
        .icon {
          margin-top: 5px;
          margin-left: 5px;
          border: 5px solid #434751;
          border-color: #434751 transparent transparent transparent;
        }
        .icon2 {
          margin-top: -5px;
          margin-left: 5px;
          border-color: transparent transparent #434751 transparent;
        }
      }
    }
    .content {
      padding: 14px 0 0;
      background: #f4f5f6;
      // height: calc(100% - 48px);
      // overflow: hidden;
      // overflow-y: auto;
      // -webkit-overflow-scrolling: touch;
      .inner {
        // padding-bottom: 127px;
        box-shadow: 0px 2px 7px 0px rgba(194, 194, 194, 0.53);
      }
    }
  }
</style>
